.movies{
    margin: 0px 100px;
    display: flex;
    /* padding: 10px 40px; */
    /*justify-content: space-evenly;*/
    gap: 8px 32px;
    justify-content: space-evenly;
    align-items: center;
}
.movies img{
    border-radius: 5px;
}
.movies h3,h4{
    font-size: 15px;
}
.movie .title{
    margin: 0px 130px;
    padding: 10px 0px;
}
.movie .title p{
    color: red;
    font-size: 20px;
    padding-top: 5px;
}
.title{
    display: flex;
    justify-content: space-between;
}
.movie{
    padding-bottom: 10px;
    background-color:rgb(218,218,218);
}


