@media screen and (max-width:600px)
{
    .navbar{
        position: sticky;
        top:0px;
        z-index: 1;
        background-color: rgb(51, 53, 69);
        background-size: cover;
        width: 100vw;
        justify-content: space-between;
        padding: 10px 40px;
    }
}

.navbar , .logo , .links{
    display: flex;
    align-items: center;
}
.navbar{
    position: sticky;
    top:0px;
    z-index: 1;
    background-color: rgb(51, 53, 69);
    justify-content: space-between;
    padding: 10px 40px;
}
.logo input{
    border: none;
    outline: none;
    width: 450px;
    height: 30px;
    border-radius: 4px;
    margin-left: 20px;
}
.links li{
    display: inline-block;
    margin: 0px 10px;
}
.links a{
    color: white;
    text-decoration: none;
}
.links button{
    background-color:rgb(248, 68, 100) ;
    padding: 5px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    
}
