*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.flex .left img{
    height: 200px;
    width: 200px;
    margin-left: 50px;
    margin-top:100px ;
}
.addfav{
    background-color: rgba(226, 226, 226, 0.863);
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.form{
    display: block;
    height: 400px;
    width: 600px;
    background-color: white;
    border: none;
    box-shadow: 0px 0px 2px black;
    border-radius: 10px;
}
.flex{
    display: flex;
}
.left{
    display: block;
    height: 400px;
    width:300px;
}
.right{
    display: block;
    height: 400px;
    width: 300px;
}
.right .heading h1{
    text-align: center;
    margin-top: 10px;
}

 .title, .genre, .rating, .button{ 
    margin: 25px 15px;
}
.title input, .genre input, .rating input{
    padding: 10px 40px;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 0px 1px black;
    background-color: rgba(244, 244, 244, 0.847);
}
.button button{
    background-color: rgba(2, 255, 255, 0.919);
    font-size: 15px;
    font-weight: 400;
    margin-left: 100px;
}
